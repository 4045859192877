/* jshint ignore:start */

var questionnaire = null;
var contact = null;
var doctor_registration = null;

(function ($) {


    function getInternetExplorerVersion() {
        var rv = -1;
        if (navigator.appName == 'Microsoft Internet Explorer') {
            var ua = navigator.userAgent;
            var re = new RegExp("MSIE ([0-9]{1,}[\.0-9]{0,})");
            if (re.exec(ua) != null)
                rv = parseFloat(RegExp.$1);
        }
        else if (navigator.appName == 'Netscape') {
            var ua = navigator.userAgent;
            var re = new RegExp("Trident/.*rv:([0-9]{1,}[\.0-9]{0,})");
            if (re.exec(ua) != null)
                rv = parseFloat(RegExp.$1);
        }
        return rv;
    }


    $(document).ready(function () {

        questionnaire = {

            'data': {},

            'vars': {
                'steps': 3,
                'step': 1,
                'questionnaire_container': $('#questionnaire'),
                'testResult': false

            },

            init: function () {

                var GTM_Data_forms = { "event": "formView" };
                GTM_Data_forms.formName = "Parkinson-Check Diagnosis Form";
                GTM_Data_forms.formStep = "Form View";
                GTM_Data_forms.formStepNr = "1";
                GTM_Data_forms.formStepCount = "3";
                GTM_Data_forms.formQuestion = "n/a";
                GTM_Data_forms.formQuestionNr = "n/a";
                GTM_Data_forms.formAnswer = "n/a";

                publishToGoogle(JSON.parse(JSON.stringify(GTM_Data_forms)));

                //close menue overlay
                $('#menu').removeClass("overlay--visible");

                // populate Data (overwrite test data)
                $('[data-question]').each(function (index, el) {
                    questionnaire.data[$(this).data('question')] = '';
                });

                $('select:not(.ignore)').niceSelect();
                FastClick.attach(document.body);

                // Binding
                $(document).on('click', '.questionnaire__pdf', function (event) {
                    event.preventDefault();
                    questionnaire.generatePDF($(this));
                });

                $(document).on('click', '.resultspermail', function (event) {
                    $('.overlay_container.share-testresult-mail').addClass("visible");
                    $('.overlay_container.share-testresult-mail').fadeIn('fast');
                    event.preventDefault();
                });
                $(document).on('click', '.share-testresult-mail .close_overlay', function () {
                    $('.overlay_container.share-testresult-mail').fadeOut('fast');
                    $('.overlay_container.share-testresult-mail').removeClass('visible');
                    return false;
                });

                $(document).on('click', '.btn-tracked-unterstuetzer', function (event) {
                    GTM_Data_forms.formStep = "Find Supporter Button Click";
                    GTM_Data_forms.formStepNr = "2.2";
                    GTM_Data_forms.formQuestion = "n/a";
                    GTM_Data_forms.formQuestionNr = "n/a";
                    GTM_Data_forms.formAnswer = "n/a";

                    publishToGoogle(JSON.parse(JSON.stringify(GTM_Data_forms)));
                });

                $(document).on('click', '.questionnaire__container .questionnaire-previous', questionnaire.previous_page);
                $(document).on('click', '.questionnaire__container .questionnaire-next', questionnaire.next_page);

                $(document).on('click', '#menu-button-questionnaire', function (event) {
                    questionnaire.reset();
                });

                // standard radio
                $(document).on('change', '[data-question] input.checkbox', function (event) {
                    $(this).parents('[data-question]').removeClass('error');
                    questionnaire.update(event);

                    //gtm tracking single select question
                    GTM_Data_forms.event = "formStep";
                    GTM_Data_forms.formStepNr = $(this).closest(".questionnaire__question").attr("data-gtm-formstep-nr");
                    GTM_Data_forms.formStep = "Section " + GTM_Data_forms.formStepNr.charAt(0) + " Answer Select";
                    GTM_Data_forms.formQuestion = $(this).closest(".questionnaire__question").children("p")[0].innerHTML.replace(/(<([^>]+)>)/gi, "");;
                    GTM_Data_forms.formQuestionNr = $(this).closest(".questionnaire__question").attr("data-gtm-question-nr");
                    GTM_Data_forms.formAnswer = $(this).closest(".questionnaire__question__checkbox").children()[1].innerHTML;

                    //set form field for email result
                    $('.overlay_container.share-testresult-mail input#field_5a3').val(window.location.protocol + '//' + vars.hostname + '/wp-json/ac/public/getPCPdf/?data=' + encodeURIComponent(JSON.stringify(questionnaire.data), true) + '&result=' + questionnaire.vars.testResult);
                    publishToGoogle(JSON.parse(JSON.stringify(GTM_Data_forms)));
                });

                // one select
                $(document).on('change', '[data-question]:not(.prevent_select_auto_binding) select', function (event) {
                    $(this).parents('[data-question]').removeClass('error');
                    questionnaire.update(event);
                    //gtm tracking single select question
                    GTM_Data_forms.event = "formStep";
                    GTM_Data_forms.formStepNr = $(this).closest(".questionnaire__question").attr("data-gtm-formstep-nr");
                    GTM_Data_forms.formStep = "Section " + GTM_Data_forms.formStepNr.charAt(0) + " Answer Select";
                    GTM_Data_forms.formQuestion = $(this).closest(".questionnaire__question").children()[1].innerHTML;
                    GTM_Data_forms.formQuestionNr = $(this).closest(".questionnaire__question").attr("data-gtm-question-nr");
                    GTM_Data_forms.formAnswer = $(this).find('option:selected').html();

                    publishToGoogle(JSON.parse(JSON.stringify(GTM_Data_forms)));
                });

                //  text
                $(document).on('change', '[data-question] input[type="text"]', function (event) {
                    $(this).parents('[data-question]').removeClass('error');
                    questionnaire.update(event);
                });

                //textarea
                $('[data-question] textarea').bind('input propertychange', function(event) {
                    questionnaire.update(event);
                    $('.overlay_container.share-testresult-mail input#field_5a3').val(window.location.protocol + '//' + vars.hostname + '/wp-json/ac/public/getPCPdf/?data=' + encodeURIComponent(JSON.stringify(questionnaire.data), true) + '&result=' + questionnaire.vars.testResult);
              });

                //refill email form before every (new) submit
                $(document).on('click', '#form_ojlrsl30079e10f483 .frm_final_submit', function (event) {
                    $('.overlay_container.share-testresult-mail input#field_5a3').val(window.location.protocol + '//' + vars.hostname + '/wp-json/ac/public/getPCPdf/?data=' + encodeURIComponent(JSON.stringify(questionnaire.data), true) + '&result=' + questionnaire.vars.testResult);
                 });

                
            },

            toogle_conditional_fields: function () {

                if (questionnaire.data.off_phases == 'true') {
                    $('[data-question="off_phases_handycap"]').slideDown(200).attr('data-visible', 'true').removeClass('error');
                } else {
                    $('[data-question="off_phases_handycap"]').slideUp(200).attr('data-visible', 'false').removeClass('error');
                }
                
                if (questionnaire.data.motoric_fluctuations == 'true') {
                    $('[data-question="motoric_fluctuations_frequency"]').slideDown(200).attr('data-visible', 'true').removeClass('error');
                    $('[data-question="motoric_fluctuations_amount"]').slideDown(200).attr('data-visible', 'true').removeClass('error');
                    
                } else {
                    $('[data-question="motoric_fluctuations_frequency"]').slideUp(200).attr('data-visible', 'false').removeClass('error');
                    $('[data-question="motoric_fluctuations_amount"]').slideUp(200).attr('data-visible', 'false').removeClass('error');
                }

                if (questionnaire.data.restrictions == 'true') {
                    $('[data-question="restrictions_support"]').slideDown(200).attr('data-visible', 'true').removeClass('error');
                } else {
                    $('[data-question="restrictions_support"]').slideUp(200).attr('data-visible', 'false').removeClass('error');
                }

                if (questionnaire.data.dyskin == 'true') {
                    $('[data-question="dyskin_frequency"]').slideDown(200).attr('data-visible', 'true').removeClass('error');
                } else {
                    $('[data-question="dyskin_frequency"]').slideUp(200).attr('data-visible', 'false').removeClass('error');
                }

                if (questionnaire.data.further_pain == 'true') {
                    $('[data-question="further_pain_textfield"]').slideDown(200).attr('data-visible', 'true').removeClass('error');
                } else {
                    $('[data-question="further_pain_textfield"]').slideUp(200).attr('data-visible', 'false').removeClass('error');
                }

            },


            update: function (event) {

                var type = event.target.type;
                var value;
                var name;

                //define type
                switch (type) {

                    case 'radio': //checkboxes
                        value = event.target.value;
                        name = event.target.name;
                        questionnaire.data[name] = value;
                        break;
                    case 'checkbox': //real checkboxes xD
                        value = event.target.value;
                        name = event.target.name;
                        if (!questionnaire.data[name]) {
                            questionnaire.data[name] = {"boxes":[]};
                        }
                        if (questionnaire.data[name].boxes.indexOf(value) !== -1) {
                            questionnaire.data[name].boxes.splice(index, 1);
                        }
                        else{
                            questionnaire.data[name].boxes.push(value);
                        }
                        console.log(questionnaire.data[name]);
                        break;

                    case 'select-one': // selects
                        value = event.target.value;
                        name = event.target.name;
                        questionnaire.data[name] = value;
                        break;

                    case 'text': // selects
                        value = $.parseJSON(event.target.value);
                        name = event.target.name;
                        questionnaire.data[name] = value;
                        break;

                    case 'textarea': // text
                        value = event.target.value;
                        name = event.target.name;

                        if ($(event.target).hasClass('additive')) {
                            if (!questionnaire.data[name]) {
                                questionnaire.data[name] = {"other": "", "boxes":[]};
                            }
                            questionnaire.data[name].other = value;
                        }
                        break;

                    default:

                }


                // trigger hidden fields logic
                questionnaire.toogle_conditional_fields();


            },

            reset: function () {
                //Reset the step
                questionnaire.vars.step = 1;
                $('#questionnaire').data('step', questionnaire.vars.step);
            },

            validate: function () {

                // init
                var data = questionnaire.data;
                var error = [];

                // check data on empty questions
                for (var key in data) {
                    if (data.hasOwnProperty(key)) {

                        var isVisible = ($('[data-question="' + key + '"]').attr('data-visible') == 'false' ? false : true);
                        var isInStep = $('.questionnaire__block:eq(' + (questionnaire.vars.step - 2) + ')').find('[data-question="' + key + '"]').length;

                        if (isInStep && isVisible) {
                            // exlude certain modules from validation
                            if (key.match(/complaints_time_window/)) {
                                continue;
                            }
                            // check if value is empty
                            if (!data[key]) {
                               
                                error.push(key);

                            }
                        }
                        if (data[key] == 'true') {
                            questionnaire.vars.testResult = "sick";
                        }
                    }
                }


                if (!error.length) {
                    if (questionnaire.vars.step == 2) {
                        $(".questionnaire__controls__finish").hide();
                    }
                    return true;
                } else {
                    // error handling
                    error.map(function (module) {
                        $('[data-question="' + module + '"]').addClass('error');
                    });
                    return false;
                }


            },
            next_page: function () {

                $('[data-question]').removeClass('error');
                $(".questionnaire__controls__finish").show();

                if (questionnaire.validate()) {
                    //GTM Tracking
                    var GTM_Data_forms = {};
                    GTM_Data_forms.formName = "Parkinson-Check Diagnosis Form";
                    GTM_Data_forms.formStepNr = "3";
                    GTM_Data_forms.formQuestion = "n/a";
                    GTM_Data_forms.formQuestionNr = "n/a";
                    GTM_Data_forms.formAnswer = "n/a";
                    GTM_Data_forms.formStepCount = "3";

                    GTM_Data_forms.event = "formSubmit";
                    GTM_Data_forms.formStep = "Form Submit";

                    questionnaire.vars.step++;

                    publishToGoogle(JSON.parse(JSON.stringify(GTM_Data_forms)));

                    $('#questionnaire').attr('data-step', questionnaire.vars.step);
                    questionnaire.calc_result();

                } else {

                }

            },
            previous_page: function () {

                //console.log('Schritt zurück');
                $('[data-question]').removeClass('error');

                questionnaire.vars.step--;
                $('#questionnaire').attr('data-step', questionnaire.vars.step);

            },
            generatePDF: function (button) {

                button.find('.button__text').addClass('fadeout');
                button.find('.anim--processing').removeClass('fadeout');
                var resultLink = window.location.protocol + '//' + vars.hostname + '/wp-json/ac/public/getPCPdf/?data=' + encodeURIComponent(JSON.stringify(questionnaire.data), true) + '&result=' + questionnaire.vars.testResult;

                if (getInternetExplorerVersion()) {
                    window.open(resultLink);
                } else {

                    var req = new XMLHttpRequest();
                    req.open("get", resultLink);
                    req.responseType = "blob";

                    req.onload = function (event) {
                        var blob = req.response;
                        var link = document.createElement('a');
                        link.target = '_blank';
                        link.href = window.URL.createObjectURL(blob);
                        link.download = "AbbVie-Care.de – PC Fragebogen.pdf";
                        document.body.appendChild(link);
                        link.click();

                        button.find('.button__text').removeClass('fadeout');
                        button.find('.anim--processing').addClass('fadeout');

                    };

                    req.send();

                }


            },
            calc_result: function () {


                // Result Logic removed, always show same sentence

                if (questionnaire.vars.testResult == "sick") {
                    $('.selbsttest-content').html('Ihr Testergebnis gibt Hinweise darauf, dass Ihre derzeitige orale Therapie nicht ausreichend wirksam sein könnte.  Ihr Testergebnis kann Ihnen als Orientierung für Ihr nächstes Arztgespräch dienen. Besprechen Sie es bitte mit Ihrer Neurologin oder Ihrem Neurologen. Sie oder er kann beurteilen, ob die von Ihnen angegebenen Symptome darauf hinweisen, dass Ihre orale Therapie nicht mehr optimal wirkt. Der Test kann Ihre Ärztin oder Ihren Arzt bei der Entscheidung unterstützen, ob Ihre orale Therapie angepasst oder eine nicht orale Folgetherapie in Betracht gezogen werden sollte.');
                }
                else{
                    $('.selbsttest-content').html('Ihr Testergebnis weist darauf hin, dass Ihre orale Therapie derzeit ausreichend wirksam ist. Klären Sie dies bei Ihrem nächsten Arztbesuch aber vorsichtshalber mit Ihrer Neurologin oder Ihrem Neurologen ab. ');
                }

                $('body').addClass("questionnaire-filled");
                $('.content-header .container h1').html('Sie haben Ihren Parkinson-Selbsttest erfolgreich abgeschlossen!');
                $('.content-header .container h2').html('Ergebnis');

                $('.overlay.questionnaire').animate({
                    scrollTop: $('.header-overlay').offset().top -400
                }, 10);
                document.getElementById('questionnaire').classList.toggle('overlay--visible');
                document.getElementsByTagName('body')[0].classList.toggle('overlay-active');
                $('[data-question="further_pain"]').slideDown(200).attr('data-visible', 'true').removeClass('error');
                $('[data-question="more_questions"]').slideDown(200).attr('data-visible', 'true').removeClass('error');
                $('[data-question="goals"]').slideDown(200).attr('data-visible', 'true').removeClass('error');

            }

        };

        checklist = {

            'data': {},

            'vars': {
                'steps': 3,
                'step': 1,
                'checklist_container': $('#checklist'),
                'testResult': false

            },

            init: function () {

                //close menue overlay
                $('#menu').removeClass("overlay--visible");

                // populate Data (overwrite test data)
                $('[data-question]').each(function (index, el) {
                    checklist.data[$(this).data('question')] = '';
                });

                $('select:not(.ignore)').niceSelect();
                FastClick.attach(document.body);

                // Binding
                $(document).on('click', '.checklist__pdf', function (event) {
                    event.preventDefault();
                    checklist.generatePDF($(this));
                });

                $(document).on('click', '#menu-button-checklist', function (event) {
                    checklist.reset()
                });

                // standard radio
                $(document).on('change', '[data-question] input.checkbox', function (event) {
                    $(this).parents('[data-question]').removeClass('error');
                    checklist.update(event);
                });

                // one select
                $(document).on('change', '[data-question]:not(.prevent_select_auto_binding) select', function (event) {
                    $(this).parents('[data-question]').removeClass('error');
                    checklist.update(event);
                });

                //  text
                $(document).on('change', '[data-question] input[type="text"]', function (event) {
                    $(this).parents('[data-question]').removeClass('error');
                    checklist.update(event);
                });

                //textarea
                $('[data-question] textarea').bind('input propertychange', function(event) {
                    checklist.update(event);
              });

            },


            update: function (event) {

                var type = event.target.type;
                var value;
                var name;

                //define type
                switch (type) {

                    case 'radio': //checkboxes
                        value = event.target.value;
                        name = event.target.name;
                        checklist.data[name] = value;
                        break;
                    case 'checkbox': //real checkboxes xD
                        value = event.target.value;
                        name = event.target.name;
                        if (!checklist.data[name]) {
                            checklist.data[name] = {"boxes":[]};
                        }
                        if (checklist.data[name].boxes.indexOf(value) !== -1) {
                            checklist.data[name].boxes.splice(index, 1);
                        }
                        else{
                            checklist.data[name].boxes.push(value);
                        }
                        console.log(checklist.data[name]);
                        break;

                    case 'select-one': // selects
                        value = event.target.value;
                        name = event.target.name;
                        checklist.data[name] = value;
                        break;

                    case 'text': // selects
                        value = $.parseJSON(event.target.value);
                        name = event.target.name;
                        checklist.data[name] = value;
                        break;

                    case 'textarea': // text
                        value = event.target.value;
                        name = event.target.name;

                        if ($(event.target).hasClass('additive')) {
                            if (!checklist.data[name]) {
                                checklist.data[name] = {"other": "", "boxes":[]};
                            }
                            checklist.data[name].other = value;
                        }
                        break;

                    default:
                }
            },

            reset: function () {
                //Reset the step
                checklist.vars.step = 1;
                $('#checklist').data('step', checklist.vars.step);
            },

            validate: function () {
                var filled = false;
                
                //check if atleast one checkbox is checked
                $('input[type="checkbox"]').each(function( index ) {
                   if ($(this).is(':checked')) {
                    filled = true;
                   }
                });
                if (!filled) {
                    // error handling
                    $('.form__info').fadeIn();
                    
                    return false;
                }
                else{
                    //download tracking
                    var GTM_Data_downloads = { "event": "download" };
                    GTM_Data_downloads.downloadLink = "N/A";
                    GTM_Data_downloads.downloadFile = "AbbVie-Care - Parkinson-Ziele.pdf";

    
                    publishToGoogle(JSON.parse(JSON.stringify(GTM_Data_downloads)));

                    return true;
                }
            
            },

           
            generatePDF: function (button) {

                if(checklist.validate()){
                    button.find('.button__text').addClass('fadeout');
                    button.find('.anim--processing').removeClass('fadeout');
                    var resultLink = window.location.protocol + '//' + vars.hostname + '/wp-json/ac/public/getPCChecklistPdf/?data=' + encodeURIComponent(JSON.stringify(checklist.data), true);
    
                    if (getInternetExplorerVersion()) {
                        window.open(resultLink);
                    } else {
                        var req = new XMLHttpRequest();
                        req.open("get", resultLink);
                        req.responseType = "blob";
    
                        req.onload = function (event) {
                            var blob = req.response;
                            var link = document.createElement('a');
                            link.target = '_blank';
                            link.href = window.URL.createObjectURL(blob);
                            link.download = "AbbVie-Care - Parkinson-Ziele.pdf";
                            document.body.appendChild(link);
                            link.click();
                            button.find('.button__text').removeClass('fadeout');
                            button.find('.anim--processing').addClass('fadeout');
                        };
                        req.send();
                    }
                }
                
            }
        };


        if($('#questionnaire').length) questionnaire.init();
        if($('#checklist').length) checklist.init();


    });

})(jQuery); // Fully reference jQuery after this point.


/* jshint ignore:end */
