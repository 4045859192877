jQuery.abbvieLayer = function () {
   options = arguments[0] ? arguments[0] : {};

   settings = jQuery.extend({
    'id' : '',
    'hasButton': false,
    'buttonTitle': 'Ok'
    }, options);

  if (! settings.id || settings.id.length < 1) {
    console.warn('id is not provided for abbvieLayer');
    return;
  }

  function Layer(opts) {
    var src = jQuery('#' + opts.id);
    this.layer_ = jQuery('<div class="abbvie-layer hidden '+ settings.id +'"><div class="abbvie-layer__hd abbvie-layer__close"></div><div class="abbvie-layer__bd--wraper"><div class="abbvie-layer__bd"></div></div><div class="abbvie-layer__actions"></div></div>')
    .appendTo('body');
    src.clone().appendTo(this.layer_.find('.abbvie-layer__bd'));
    src.addClass('abbvie-layer__source');
    src.attr("id", opts.id + "_layer");

    if (opts.hasButton) {
      var button = jQuery('<a href="" class="button button--ghost abbvie-layer__close"><span class="button__text">' + opts.buttonTitle + '</span></a>')
        .appendTo(this.layer_.find('.abbvie-layer__actions'));
    }

    this.backdrop_ = jQuery('<div></div>').css({
      'position' : 'fixed',
      'top' : '0',
      'left' : '0',
      'width' : jQuery(window).width(),
      'height' : jQuery(window).height()
    }).addClass('abbvie-layer__backdrop hidden').appendTo('body');

    this.layer_.find('.abbvie-layer__close').click( function (e) {
      e.preventDefault();
      e.stopPropagation();
      this.close();
    }.bind(this));
  }

  /**
  * API call to show the layer
  */
  Layer.prototype.show = function () {
    this.layer_.removeClass('hidden');
    this.layer_.addClass('visible');

    this.backdrop_.removeClass('hidden');
    this.backdrop_.addClass('visible');
    jQuery('body').addClass('abbvie-layer-open');
  };

  /**
  * Api call to hide the layer
  */
  Layer.prototype.close = function () {
    this.layer_.addClass('fade-out');
    this.backdrop_.addClass('fade-out');
    jQuery('body').removeClass('abbvie-layer-open');

    setTimeout(this.afterClose_.bind(this), 300);
  };

  /**
  * Called after fade-out animation to reset layer state
  * @private
  */
  Layer.prototype.afterClose_ = function() {
    this.layer_.addClass('hidden');
    this.layer_.removeClass('visible');
    this.layer_.removeClass('fade-out');

    this.backdrop_.addClass('hidden');
    this.backdrop_.removeClass('visible');
    this.backdrop_.removeClass('fade-out');
  };

  return new Layer(settings);
};
