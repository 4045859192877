/* jshint ignore:start */


var isSafari = navigator.userAgent.indexOf("Safari") > -1;
var isChrome = navigator.userAgent.indexOf('Chrome') > -1;
if ((isChrome) && (isSafari)) {
    isSafari = false;
}
var msie = navigator.userAgent.indexOf("MSIE ");
var msie11 = navigator.userAgent.match(/Trident\/7\./);

function getCookie(name) {
    var dc = document.cookie;
    var prefix = name + "=";
    var begin = dc.indexOf("; " + prefix);
    if (begin === -1) {
        begin = dc.indexOf(prefix);
        if (begin !== 0) {
            return null;
        }
    } else {
        begin += 2;
        var end = document.cookie.indexOf(";", begin);
        if (end === -1) {
            end = dc.length;
        }
    }
    return unescape(dc.substring(begin + prefix.length));
}


function positionHeroImage($el) {
    $el.css('margin-right', '-' + ($el.innerWidth() - 25) + 'px');

    //Dirty hack to FFOX to first add margin and then recalculate again (it resizes the image afer chaning its position)
    setTimeout(function () {
        $el.css('margin-right', '-' + ($el.innerWidth() - 25) + 'px');
        $el.removeClass('transparent');
    }, 200);
}

function waitHeroLoaded($el) {
    setTimeout(function () {
        var width = $el.width();
        if (width > 0) {
            positionHeroImage($el);
        } else {
            waitHeroLoaded($el);
        }
    }, 200);
}

(function ($) {

    $(document).ready(function () {


        // IE 11 Fallback CSS CLASS
        if (msie11) {
            $('html').addClass('ie11');
        }
        // IE fix pointer events none;
        $(document).on('click mouseenter mousemove mouseleave', '.button--disabled', function (event) {
            event.stopImmediatePropagation();
            event.preventDefault();
            return false;
        });

        $(document).on('click', '.button.calendar-date', function(event) {
            $('.calendar-event').toggleClass('overlay--visible');
            $('body').toggleClass('overlay-active');
            event.preventDefault();
        });
        $(document).on('click', '.calendar-event .close_overlay', function (event) {
            $('.calendar-event').toggleClass('overlay--visible');
            document.getElementsByTagName('body')[0].classList.toggle('overlay-menue');
             if (!$('.questionnaire').hasClass("overlay--visible")) {
                document.getElementsByTagName('body')[0].classList.toggle('overlay-active');
             }

            event.preventDefault();
        });
        $(document).on('input', '.additive', function (event) {
            $(this).parent().find("input[type='checkbox']").prop('checked', true);
        });
        
        $(document).on('click', '.submit-calendar-date', function(event) {
            var value_date = document.getElementById("date-picker").value;

            var value_time = document.getElementById("time-picker").value;
            if(!value_date && !value_time){
                return false;
            }
            var resultLink = window.location.protocol + '//' + vars.hostname + '/wp-json/ac/public/getCalendarDatePC/?date=' + value_date + ' ' + value_time;
      
            var req = new XMLHttpRequest();
            req.open("get", resultLink);
            req.responseType = "blob";

            req.onload = function (event) {
                var blob = req.response;
                var link = document.createElement('a');
                link.target = '_blank';
                link.href = window.URL.createObjectURL(blob);
                link.download = "Parkinson-Check Arzttermin.ics";
                document.body.appendChild(link);
                link.click();
            };

            req.send();
            
            var GTM_Data_forms = { "event": "formStep" };
                GTM_Data_forms.formName = "Parkinson-Check Diagnosis Form";
                GTM_Data_forms.formStep = "Calendar Date Button Click";
                GTM_Data_forms.formStepNr = "3.2";
                GTM_Data_forms.formStepCount = "3";
                GTM_Data_forms.formQuestion = "n/a";
                GTM_Data_forms.formQuestionNr = "n/a";
                GTM_Data_forms.formAnswer = "n/a";
                publishToGoogle(JSON.parse(JSON.stringify(GTM_Data_forms)));

        });
        
        function scrollToElement(element,hashscroll) {

            var offset = 65;

            if($(window).width() < 1240 && element.attr('id') == 'kampagnenstory') {
                offset = 65 + 320;
            }

            if($(window).width() < 769 && element.attr('id') == 'kampagnenstory') {
                offset = 65 + 230;
            }

            if(hashscroll) offset += 100;

            if(element.attr('id') == 'cta-mitmachaktion') {
                offset = 0;
            }

            setTimeout(function() {
                $('html, body').animate({scrollTop: element.offset().top - offset}, 500);
            }, 350);

            setTimeout(function() {
                $(document.body).removeClass('scroll-up');
                $(document.body).addClass('scroll-down');
            }, 500);
        }

        if (isSafari) {
            $(document.body).addClass('is-safari');
        }

        if (msie > -1 || msie11) {
            $(document.body).addClass('is-ie');
        }

// Init

        var config = {
            enter: 'bottom',
            scale: 0,
            distance: '35px',
            duration: 700,
            delay: 150
        };


        if (vars.useSr) { // vars.Object defined in /inject.php

            $('[data-sr-id]').removeAttr('data-sr-id').removeAttr('style'); // https://github.com/jlmakes/scrollreveal/issues/303
            window.sr = ScrollReveal(config);
            sr.reveal('.appear');
            sr.reveal('.appear.appear--item', 150);

        }





// Menü

        document.getElementById('menu-button').addEventListener('click', (function () {
            document.getElementById('menu').classList.toggle('overlay--visible');
            document.getElementsByTagName('body')[0].classList.toggle('overlay-menue');
             if (!$('.questionnaire').hasClass("overlay--visible") && !$('.checklist').hasClass("overlay--visible")) {
                document.getElementsByTagName('body')[0].classList.toggle('overlay-active');
             }
            // else{
            //     document.getElementsByTagName('body')[0].classList.toggle('overlay-active');
            // }
        }));




// Events

        var eventCounter = 0;

        $(document).on('click', '.campaign__events__all', function (event) {


            $(this).parent('.campaign__events__nav').hide();
            $('.campaign__events__list--share').detach();

            $('.campaign__events__nav').removeClass('campaign__events__nav--hidden');

            //$('.campaign__events__next').click();
            $('.campaign__events__content .campaign__events__list:first').slideDown();

            //eventCounter = $('.campaign__events__content .campaign__events__list:visible:last').index();

            return false;

        });

        $(document).on( 'frmFormComplete', function( event, form, response ) {
            var formID = $(form).find('input[name="form_id"]').val();

            if (formID == "21") {
                var GTM_Data_forms = { "event": "formStep" };
                GTM_Data_forms.formName = "Parkinson-Check Diagnosis Form";
                GTM_Data_forms.formStep = "Send Results to yourself Button Click";
                GTM_Data_forms.formStepNr = "3.3";
                GTM_Data_forms.formStepCount = "3";
                GTM_Data_forms.formQuestion = "n/a";
                GTM_Data_forms.formQuestionNr = "n/a";
                GTM_Data_forms.formAnswer = "n/a";
                publishToGoogle(JSON.parse(JSON.stringify(GTM_Data_forms)));
            
            }
   
        });



        $(document).on('click', '.campaign__events__next', function (event) {

            eventCounter++;
            $('.campaign__events__content .campaign__events__list:eq('+ (eventCounter) +')').slideDown();
            $('.campaign__events__content .campaign__events__list:eq('+ (eventCounter) +') .campaign__events__item').css('opacity',1); // Dirty!

            //console.log($('.campaign__events__content .campaign__events__list').length, $('.campaign__events__content .campaign__events__list:visible').length);

            if($('.campaign__events__content .campaign__events__list').length == $('.campaign__events__content .campaign__events__list:visible').length) {
                $('.campaign__events__next').addClass('button--disabled');
            }

            event.preventDefault();

        });


// Menü Button Overlay


        $(document).on('click', '#menu-button-overlay', function (event) {
            document.getElementById('menu').classList.toggle('overlay--visible');
            document.getElementsByTagName('body')[0].classList.toggle('overlay-active');
        });


// Anchor Scrolling / Hash Logic

        if (window.location.hash) {
            // $('html, body').animate({
            //     scrollTop: $('a' + window.location.hash).offset().top
            // }, 100);
            //$('body').scrollTo('a' + window.location.hash);
        }

        $(document).on('click', 'p a', function (event) {

            if($(this).attr('href').indexOf('#') && $('#'+$(this).attr('href').split("#")[1]).length) {
                scrollToElement($('#'+$(this).attr('href').split("#")[1]));
                event.preventDefault();
            }

        });


// Direct Links Fragebogen / Mitmachaktion

        if (window.location.href.match(/zum-fragebogen/)) {
            $('#questionnaire').addClass('overlay--visible');
            $('body').addClass('overlay-active');
            $('#questionnaire').attr('data-step', 1);
        }




// For formular row click callback
        function isElementInViewport(el) {

            //special bonus for those using jQuery
            if (typeof jQuery === 'function' && el instanceof jQuery) {
                el = el[0];
            }

            var rect = el.getBoundingClientRect();

            return (
                rect.top >= 0 &&
                rect.left >= 0 &&
                rect.bottom <= (window.innerHeight || document.documentElement.clientHeight) &&
                rect.right <= (window.innerWidth || document.documentElement.clientWidth)
            );
        }

        function createInputClickCallback() {
            return function() {
                // Check if field ist still in view after mobile keyboard is displayed
                var el = this;
                setTimeout(function() {
                    if (!isElementInViewport(el))
                    {
                        el.scrollIntoView();
                    }
                }, 500);
            }
        }

        var textInputs = document.querySelectorAll('input[type=text]');
        for (var i = 0; i < textInputs.length; i++)
        {
            textInputs[i].addEventListener('click', createInputClickCallback());
        }

        // Same for textarea input
        $(document).on('click', '.input contact__form__textarea', createInputClickCallback());



// Event contact callbacks

        // Show card by click on item
        var createContactClickCallback = function () {
            return function () {
                this.parentNode.classList.add('show-card');
                this.parentNode.classList.remove('show-card-hover');
            }
        };

        var contacts = document.getElementsByClassName('event__contact__link');
        for (var i = 0; i < contacts.length; i++) {
            contacts[i].addEventListener('click', createContactClickCallback());
        }


        // Close card by click on X
        var createCloseClickCallback = function () {
            return function () {
                var item = this.parentNode.parentNode;
                item.classList.add('closed');
                item.classList.remove('show-card');
                item.classList.remove('show-card-hover'); // Just to be sure

                setTimeout(function () {
                    item.classList.remove('closed');
                }, 400); // Transition time
            }
        };

        var buttons = document.getElementsByClassName('info-card__close');
        for (var i = 0; i < buttons.length; i++) {
            buttons[i].addEventListener('click', createCloseClickCallback());
        }


        // Open card when hovering
        var createMouseInCardCallback = function () {
            return function () {
                if (!this.classList.contains('show-card') && !this.classList.contains('closed')) {
                    this.classList.add('show-card-hover');
                }
            }
        };
        var createMouseOutCardCallback = function () {
            return function () {
                this.classList.remove('show-card-hover');
            }
        };

        var items = document.getElementsByClassName('event__contact__item');
        for (var i = 0; i < items.length; i++) {
            //items[i].addEventListener('mouseover', createMouseInCardCallback());
            //items[i].addEventListener('mouseleave', createMouseOutCardCallback());
        }

        $(document).on('mouseover', '.event__contact__item', function() {
            if (!this.classList.contains('show-card') && !this.classList.contains('closed')) {
                this.classList.add('show-card-hover');
            }
        });

        $(document).on('mouseleave', '.event__contact__item', function() {
            this.classList.remove('show-card-hover');
        });

// Close all Overlays with ESC

        $(document).keyup(function(e) {
            if (e.keyCode == 27) {
                if ($('body').hasClass('abbvie-layer-open')) {
                    $('body').removeClass('abbvie-layer-open')
                    $('.abbvie-layer').removeClass('visible').addClass('hidden');
                    $('.abbvie-layer__backdrop').removeClass('visible').addClass('hidden');
                } else if ($('body').hasClass('overlay-video')) {
                    closevideooverlay('true');
                } else {
                    $('.overlay--visible').removeClass('overlay--visible');
                    $('body').removeClass('overlay-active');
                }
            }
        });



// Campaign Story Sliders / Logic

        function initCampaignSliders() {

            var changeFstSldier = true;

            var campaign__story__img = new Swiper('.campaign__story__img__wrapper.swiper-container', {
                loop: true,
                preventClicks: true,
                effect: 'fade',
                noSwiping: true,
                fadeEffect: {
                    crossFade: true
                }
            });

            var campaign__story__slider = new Swiper('.campaign__story__slider__wrapper.swiper-container', {
                loop: true,
                autoHeight: true,
                simulateTouch: false,
                breakpoints: {
                    480: {
                        noSwiping: false
                    }
                },


                navigation: {
                    nextEl: '.button--campaign__story__nav.button-next',
                    prevEl: '.button--campaign__story__nav.button-prev'
                }
            });


            var campaign__story__slider__pag = '';
            var classes = '';
            var counter = 0;
            var slides_count = campaign__story__slider.slides.length - 2;
            //var slides_count= 20;

            for (var i = 1; i < (slides_count + 1); i++) {

                counter++;

                switch (counter) {
                    case 1:
                        classes = (i === 1 ? 'active' : '');
                        break;
                    case 2:
                        classes = '';
                        break;
                    case 3:
                        classes = 'pag_borderright';
                        break;
                    case 4:
                        classes = 'pag_break_push';
                        break;
                    case 5:
                        classes = 'pag_borderright';
                        break;
                    case 6:
                        classes = 'pag_break';
                        counter = 1;
                }

                campaign__story__slider__pag += '<li data-id="' + i + '" class="' + classes + '">' + i + '</li>';
            }


            $('.campaign__story__pag ul').html(campaign__story__slider__pag);


            $(document).on('click', '.campaign__story__pag ul li', function () {
                campaign__story__img.slideTo($(this).attr('data-id'));
                campaign__story__slider.slideTo($(this).attr('data-id'));
                $('.campaign__story__pag ul li').removeClass('active');
                $('.campaign__story__pag ul li:eq(' + (campaign__story__slider.realIndex) + ')').addClass('active');

            });

            campaign__story__img.on('slideChange', function () {
                changeFstSldier = false;
                campaign__story__slider.slideTo(campaign__story__img.activeIndex);
                changeFstSldier = true;

            });


            campaign__story__slider.on('slideChange', function () {

                $('.showreadmore').removeClass('showreadmore');
                $('.page__section__row.campaign__story__content').slideUp();

                if (changeFstSldier) {
                    campaign__story__img.slideTo(campaign__story__slider.activeIndex);
                    $('.campaign__story__pag ul li').removeClass('active');
                    $('.campaign__story__pag ul li:eq(' + (campaign__story__slider.realIndex) + ')').addClass('active');
                }

            });




// Read more button

            $(document).on('click', '[class*="swiper-slide"] .button--readmore', function() {

                var slide = $(this).parents('.swiper-slide');
                slide.addClass('showreadmore');
                var hiddencontent = $(this).parents('.swiper-slide').find('.campaign__story__content'); //document.getElementsByClassName('campaign__story__content')[0];

                $('.swiper-wrapper').addClass('heightauto');
                hiddencontent.slideDown();

            });

            


            $(document).on('click', '[class*="swiper-slide"] .campaign__story__closemore', function(event) {

                var slide = $(this).parents('.swiper-slide');
                slide.removeClass('showreadmore');
                var hiddencontent = $(this).parents('.swiper-slide').find('.campaign__story__content'); //document.getElementsByClassName('campaign__story__content')[0];
                hiddencontent.slideUp(400, function() {
                    $('.swiper-wrapper').removeClass('heightauto');
                    campaign__story__slider.update();
                });

                event.preventDefault();

            });

       



            //share logic

            if(urlparams.share_type !== undefined && urlparams.share_type == 'story' && !ignore_share_action ) {

                jQuery('html, body').animate({
                    'scrollTop': jQuery('#kampagnenstory').offset().top - 100
                }, 500, '', function() {
                    campaign__story__slider.slideTo(urlparams.share_id);
                });

            }

            if(urlparams.share_type !== undefined && urlparams.share_type == 'event') {

                jQuery('html, body').animate({
                    'scrollTop': jQuery('#veranstaltungen').offset().top - 100
                }, 500, '', function() {
                });

            }



        }

        if($('body').hasClass('postid-21476')) {
            initCampaignSliders();
        }





// DOI Overlay


        $(document).on('click', '.doi_overlay__link', function (event) {
            $('.doi_overlay').detach();
            event.preventDefault();
        });


// Fragebogen scroll into view function
        var scrollOnQuestionnaireChange = function () {
            jQuery('body, html').animate({
                scrollTop: jQuery('.content-header .img-text').offset().top-200
            }, 400);
        };

// Fragebogen next und finsh click callbacks

        $(document).on('click','.questionnaire__controls__finish', scrollOnQuestionnaireChange);


// Global scroll event for navigation header
        var pageNavHeight = document.getElementsByClassName('page-nav__wrapper')[0].offsetHeight;
        var lastScrollY = 0;
        var lastTouchY = 0;

        $(document).on('scroll', function(event) {
            var doc = document.documentElement;
            var deltaY = (window.pageYOffset || doc.scrollTop) - (doc.clientTop || 0);

            // Reached top
            if (deltaY < pageNavHeight)
            {
                $(document.body).removeClass('scroll-up');
                $(document.body).removeClass('scroll-down');
                lastScrollY = deltaY;
                return;
            }

            if (deltaY > lastScrollY)
            {
                $(document.body).removeClass('scroll-up');
                $(document.body).addClass('scroll-down');
            }
            else if (deltaY < lastScrollY)
            {
                $(document.body).removeClass('scroll-down');
                $(document.body).addClass('scroll-up');
            }

            lastScrollY = deltaY;
        });

        $(document).on('touchmove', function(event) {
            var doc = document.documentElement;
            var deltaY = (window.pageYOffset || doc.scrollTop) - (doc.clientTop || 0);

            // Reached top
            if (deltaY < pageNavHeight)
            {
                $(document.body).removeClass('scroll-up');
                $(document.body).removeClass('scroll-down');
                lastTouchY = deltaY;
                return;
            }

            if (deltaY > lastTouchY)
            {
                $(document.body).removeClass('scroll-up');
                $(document.body).addClass('scroll-down');
            }
            else if (deltaY < lastScrollY)
            {
                $(document.body).removeClass('scroll-down');
                $(document.body).addClass('scroll-up');
            }

            lastTouchY = deltaY;
        });


// AJAX checklist INIT


$(document).on('click', '.zielsetzung-open-overlay', function (event) {

    var button = $(this);

    event.preventDefault();
   
    if($('#checklist').length) {

        $('#checklist').addClass('overlay--visible');
        $('body').addClass('overlay-active');
        $('#checklist').attr('data-step', 1);
        //hide menue
        $('#menu').removeClass("overlay--visible");

    } else {

        button.find('.button__text').addClass('fadeout');
        button.find('.anim--processing').removeClass('fadeout');

        $( "#checklist__ajaxcontainer" ).load( "/parkinson-check/?zur-checkliste #checklist", function( response, status, xhr ) {
            if ( status == "success" ) {

                checklist.init();
                $('#checklist').addClass('overlay--visible');
                $('body').addClass('overlay-active');
                $('#checklist').attr('data-step', 1);

                button.find('.button__text').removeClass('fadeout');
                button.find('.anim--processing').addClass('fadeout');

                if (button.hasClass('selbsttest-button')) {
                    checklist.next_page();
                }

            }
        });

    }
});

// AJAX Fragebogen INIT


        $(document).on('click', '.info__diagnosis__questionnaire-teaser .button, .info__diagnosis__questionnaire-button', function (event) {

            var button = $(this);

            event.preventDefault();
           
            if($('#questionnaire').length) {

                $('#questionnaire').addClass('overlay--visible');
                $('body').addClass('overlay-active');
                $('#questionnaire').attr('data-step', 1);
                //hide menue
                $('#menu').removeClass("overlay--visible");

            } else {

                button.find('.button__text').addClass('fadeout');
                button.find('.anim--processing').removeClass('fadeout');

                $( "#questionnaire__ajaxcontainer" ).load( "/parkinson-check/?zum-fragebogen #questionnaire", function( response, status, xhr ) {
                    if ( status == "success" ) {

                        questionnaire.init();
                        $('#questionnaire').addClass('overlay--visible');
                        $('body').addClass('overlay-active');
                        $('#questionnaire').attr('data-step', 1);

                        button.find('.button__text').removeClass('fadeout');
                        button.find('.anim--processing').addClass('fadeout');

                        if (button.hasClass('selbsttest-button')) {
                            questionnaire.next_page();
                        }

                    }
                });

            }
        });


// AJAX Kontakt INIT


        $(document).on('click', '.campaign__cta .cta__container .button', function (event) {

            var button = $(this);

            event.preventDefault();

            if($('#contact').length) {

                $('#contact').addClass('overlay--visible');
                $('body').addClass('overlay-active');

            } else {

                button.find('.button__text').addClass('fadeout');
                button.find('.anim--processing').removeClass('fadeout');

                $( "#contact__ajaxcontainer" ).load( "/parkinson-check/?zur-mitmachaktion #contact", function( response, status, xhr ) {
                    if ( status == "success" ) {

                        contact.init();
                        init_overlaybuttons();
                        $('#contact').addClass('overlay--visible');
                        $('body').addClass('overlay-active');

                        button.find('.button__text').removeClass('fadeout');
                        button.find('.anim--processing').addClass('fadeout');

                    }
                });

            }


        });




        // Arzt Registrierung



        $(document).on('click', '[data-binding="doctor_register"]', function (event) {

            var button = $(this);

            event.preventDefault();

            if($('#doctor_register').length) {

                $('#doctor_register').addClass('overlay--visible');
                $('body').addClass('overlay-active');
                
            } else {


                button.find('.button__text').addClass('fadeout');
                button.find('.anim--processing').removeClass('fadeout');

                $( "#doctor_register__ajaxcontainer" ).load( "/parkinson-check/?registrierung #doctor_register", function( response, status, xhr ) {
                    if ( status == "success" ) {


                        doctor_registration.init();
                        init_overlaybuttons();
                        $('#doctor_register').addClass('overlay--visible');
                        $('body').addClass('overlay-active');

                        button.find('.button__text').removeClass('fadeout');
                        button.find('.anim--processing').addClass('fadeout');

                    }
                });

             }


        });







// Exit Disclaimber


        triggerED = function(href,target) {
            vars.exit_link = href;
            vars.exit_link_target = target;
            $('a[data-layer="overlay--exitdisclaimber"]').click();
        };

        $(document).on('click', 'a[href*="http"]:not(.brightcove-video)', function (event) {

            var href = $(this).attr('href');
            var target = $(this).attr('target');

            if(isExitDisclaimberLink($(this))) {
                event.preventDefault();
                event.stopImmediatePropagation();
                triggerED(href,target);
            }

        });

        $(document).on('click', '.overlay--exitdisclaimber-gotolink', function () {
            $('.abbvie-layer__close').click();
            setTimeout(function() {
                window.open(vars.exit_link, (vars.exit_link_target ? vars.exit_link : '_self'));
            },500);
        });


//Initialize overlay buttons:

        function init_overlaybuttons() {

            $('.layer-button').each(function (idx) {
                var target = $(this);
                var layerId = target.data("layer");
                var opts = {id: layerId};
                var hasButton = target.data("button");
                var buttonTitle = target.data("button-title");

                if (hasButton) {
                    opts.hasButton = opts;
                }

                if (buttonTitle && buttonTitle.length > 0) {
                    opts.buttonTitle = buttonTitle;
                }

                if (layerId && layerId.length > 0) {
                    var layer = $.abbvieLayer(opts);
                    target.click(function (event) {
                        event.stopPropagation();
                        event.preventDefault();

                        layer.show();
                    });
                }
            });

        }

        init_overlaybuttons();

//Influencer-Overlay
        if ($('.overlay.influencer').length) {
            if (window.location.href.indexOf('nurmutvideo') > -1) {
                document.getElementById('influencer').classList.toggle('overlay--visible');
                document.getElementsByTagName('body')[0].classList.toggle('overlay-active');
            }

            var tag = document.createElement('script');

            //Influencer Overlay Video
            tag.src = "https://www.youtube.com/iframe_api";
            var firstScriptTag = document.getElementsByTagName('script')[0];
            firstScriptTag.parentNode.insertBefore(tag, firstScriptTag);

            $('#mut-video__overlay__influencer, .mut-video__headline h2').on('click', function () {
                var player = new YT.Player('mut-video__overlay__influencer', {
                    videoId: 'OIydXJn865A',
                    playerVars: {
                        'autoplay': 1,
                        'controls': 0,
                        'showinfo': 0,
                        'rel': 0,
                    },
                    events: {
                        'onReady': function (event) {
                            event.target.playVideo(); // play video
                            $('.mut-video__headline').fadeOut();
                        },
                        'onStateChange': function (event) {
                            if (event.data == YT.PlayerState.ENDED) {

                            }
                        }
                    }
                });
            });

            // Menü Button Influenceroverlay
            document.getElementById('menu-button-influencer').addEventListener('click', (function () {
                //window.location.href = window.location.href.split('?')[0];
                document.getElementById('influencer').classList.toggle('overlay--visible');
                document.getElementsByTagName('body')[0].classList.toggle('overlay-active');
                $('.overlay.influencer').remove();
            }));
        }

//Video-Overlay
        if (($('.page__section.mut-button').length) && ($('.overlay.video').length)) {

            var mutvideoplayed = Cookies.get('mutvideoplayed');
            if (typeof mutvideoplayed != 'undefined') {
                $('.mut-button__content__thebutton').removeClass('active');
                $('.mut-button__content__conclusion').addClass('active');
            }

            function setvideocookie() {
                var mutvideoplayed = Cookies.get('mutvideoplayed');
                if (typeof mutvideoplayed === 'undefined') {
                    Cookies.set('mutvideoplayed', true, { path: '/' });
                    setPostAttribs(11536,'likes','increase',null);
                }
            }

            function showconclusioncontainer() {
                setvideocookie();
                $('.mut-button__content__thebutton').removeClass('active');
                $('.mut-button__content__conclusion').addClass('active');
            }

            function closevideooverlay(showconclusion) {
                player.stopVideo();
                document.getElementById('video').classList.toggle('overlay--visible');
                document.getElementsByTagName('body')[0].classList.toggle('overlay-active');
                document.getElementsByTagName('body')[0].classList.toggle('overlay-video');

                if (showconclusion === 'true') {
                    showconclusioncontainer();
                }
            }

            var tag = document.createElement('script');

            //Influencer Overlay Video
            tag.src = "https://www.youtube.com/iframe_api";
            var firstScriptTag = document.getElementsByTagName('script')[0];
            firstScriptTag.parentNode.insertBefore(tag, firstScriptTag);
            var player;

            $('.page__section.mut-button .thebutton, .playmutvideoagain').on('click', function(e) {
                e.preventDefault();

                document.getElementById('video').classList.toggle('overlay--visible');
                document.getElementsByTagName('body')[0].classList.toggle('overlay-active');
                document.getElementsByTagName('body')[0].classList.toggle('overlay-video');

                if (typeof player == 'undefined') {
                    player = new YT.Player('mut-video__overlay', {
                        videoId: 'OIydXJn865A',
                        playerVars: {
                            'autoplay': 1,
                            'controls': 0,
                            'showinfo': 0,
                            'rel': 0,
                        },
                        events: {
                            'onReady': function (event) {
                                event.target.playVideo(); // play video
                            },
                            'onStateChange': function (event) {
                                if (event.data == YT.PlayerState.ENDED) {
                                    closevideooverlay('true');
                                }
                            }
                        }
                    });
                } else {
                    player.playVideo(false);
                }
            });

            // Menü Button VideoOverlay
            document.getElementById('menu-button-video').addEventListener('click', (function () {
                player.stopVideo();
                closevideooverlay('true');
            }));
        }

        /* RAG (RACE ACROSS GERMANY) Overlay, - disabled 20180830
        if ($('.overlay.rag').length) {
            if (window.location.href.indexOf('ragoverlay') > -1) {
                document.getElementById('rag').classList.toggle('overlay--visible');
                document.getElementsByTagName('body')[0].classList.toggle('overlay-active');
            }

            // Menü Button Influenceroverlay
            $('#menu-button-rag, .closeoverlay').on('click', function (e) {
                e.preventDefault();
                document.getElementById('rag').classList.toggle('overlay--visible');
                document.getElementsByTagName('body')[0].classList.toggle('overlay-active');
                $('.overlay.rag').remove();
            });
        }
        */
    });

    $(document).on('click', '.clip-clap-trigger', function (event) {
        $(this).closest(".clip-clap-outer").find(".clip-clap").addClass("extended");
        $(this).addClass("faded");
    });

    $(document).on('click', '.dropdown-toggle', function (event) {
        $(this).closest(".dropdown").toggleClass("extended");
    });

    $(document).on('click', '.overlay--exitdisclaimber-gotolink', function () {
        $('.abbvie-layer__close').click();
        setTimeout(function() {
            window.open(vars.exit_link, (vars.exit_link_target ? vars.exit_link : '_self'));
        },500);
    });

})(jQuery); // Fully reference jQuery after this point.



/* jshint ignore:end */